import axios from 'axios';

const service = axios.create({
  baseURL: 'https://activity.higozj.com/prod-api',
  timeout: 5000
});

service.interceptors.request.use(
  config => {
    // 设置请求头
    config.headers['Content-Type'] = 'multipart/form-data; charset=UTF-8'
    return config;
  },
  error => {
    console.log(error)
    return Promise.reject();
  }
);

service.interceptors.response.use(
  response => {
    if (response.status === 200) {
      return response.data;
    } else {
      Promise.reject();
    }
  },
  error => {
    console.log(error)
    return Promise.reject();
  }
);

export default service;