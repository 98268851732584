import axios from 'axios';
// import qs from 'qs';

const service = axios.create({
  baseURL: 'https://activity.higozj.com/prod-api',
  timeout: 5000
});

service.interceptors.request.use(
  config => {
    // 设置请求头
    config.headers['Content-Type'] = 'application/json; charset=UTF-8'
    // config.transformRequest = [function (data) {
    //   // 在请求之前对data传参进行格式转换
    //   console.log(data);
    //   data = qs.stringify(data)
    //   console.log(data);
    //   return data
    // }]
    return config;
  },
  error => {
    console.log(error)
    return Promise.reject();
  }
);

service.interceptors.response.use(
  response => {
    if (response.status === 200) {
      return response.data;
    } else {
      Promise.reject();
    }
  },
  error => {
    console.log(error)
    return Promise.reject();
  }
);

export default service;