<template>
  <div class="IndexBox">
    <div class="index_top_box">
        <img src="../assets/images/index_top.png" alt="" mode="widthFix">
    </div>
    <div class="index_bottom_box">
        <img src="../assets/images/index_bottom.png" alt="" mode="widthFix">
    </div>
    <div class="container" >
        <slot></slot>
    </div>
  </div>
</template>

<script>
export default {

}
</script>

<style lang="scss" scoped>
    .IndexBox{
        width: 100%;
        height: 100%;
       
        position: relative;
        .index_top_box{
            width: 100%;
            height: 489px;
            position: fixed;
            top: 0;
            left: 0;
            z-index: 5;
            img{
                width: 100%;
                height: 100%;
            }
        }
        .index_bottom_box{
            width: 100%;
            height: 135px;
            position: fixed;
            bottom: 0;
            left: 0;
            z-index:5;
            img{
                width: 100%;
                height: 100%;
            }
        }
        .container{
            width: 100%;
            // height: 800px;     
            position: absolute;
            z-index: 6;
            top: 289px;
            display: flex;
            flex-direction: column;
            align-items: center;
            padding: 0 20px;
            // background: blue; 
                 
        }
    }
</style>