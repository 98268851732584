import Vue from 'vue'
import App from './App.vue'
import 'amfe-flexible'
import Router from 'vue-router'
import router from './router'
import {Field,Form,Popup,Picker,Uploader,Notify} from 'vant';
Vue.use(Form);
Vue.use(Field);
Vue.config.productionTip = false
Vue.use(Popup)
Vue.use(Router)
Vue.use(Picker)
Vue.use(Uploader)
Vue.use(Notify)
new Vue({
  router,
  render: h => h(App),
}).$mount('#app')
