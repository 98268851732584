<template>
  <div id="app">
    <!-- <router-link to="/client">用户端</router-link>
    <router-link to="/official">扫码端</router-link> -->
    <router-view></router-view>
  </div>
</template>

<script>

export default {
  name: "App",
};
</script>

<style >
@import './assets/fonts';
html,body{
  background: #E9E9E9;
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #333;
}
*{
  margin: 0;
  padding: 0;
}

div{
  box-sizing: border-box;
}

.flex{
  display: flex;
  justify-content: center;
  align-items: center;
}

.flex-center{
  align-items: center;
}
.van-uploader{
  width: 96% !important;
  height: 96% !important;
  border-radius: 6px;
}
.van-uploader__wrapper{
  width: 100% !important;
  height: 100% !important;
  
}
.van-uploader__upload {
  width: 100% !important;
  height: 100% !important;
  border-radius:6px !important;
  background-color: #fff !important;
  box-sizing: border-box !important;
  margin:0 !important;

}
.van-uploader__input{
  width: 100% !important;
  height: 100% !important;
}
.van-uploader__upload-text{
  margin-top: 21px !important;
  font-family: 'regular';
}
.van-uploader__upload-icon{
  color: #3e85b7;
}
.van-uploader__preview{
  width:100%  !important;
  height: 100% !important;
  margin: 0;
}
.van-image{
    width: 100% !important;
    height: 100%!important;
    
  }
.van-image_img{
  object-fit:fill !important;
}

@media screen and (-webkit-min-device-pixel-ratio:0) { 
  select,
  textarea,
  input {
    font-size: 16px;
  }
}
</style>
