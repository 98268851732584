import Client from '../components/Client'
import Official from '../components/Official'
// import Vue from 'vue'
import Router from 'vue-router'
const routes = [
    {
        path:"/client",
        component:Client
    },
    {
        path:'/official',
        component:Official
    }
]

const router = new Router({
    routes
})

export default router