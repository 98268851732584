<template>
  <IndexBox>
    <BarScan ref="qrcode" @ok="getResult" @err="geterror"></BarScan>
    <div class="btn flex" @click="startCamera" v-if="CameraClose">点击扫码</div>
    <div class="btn flex" style="margin-top: 10px;"  v-if="!CameraClose" @click="endCamera">关闭扫码</div>
    <div class="box" v-if="CameraClose">
      <div class="item">
        <div class="title">操作说明</div>
        <div class="content">
          点击扫码按钮，扫描用户保存的报名二维码
          扫码时请仔细核对用户信息正确则审核通过 错误则不予通过
        </div>
      </div>
    </div>
    <van-popup
      v-model="show"
      position="bottom"
      round
      :style="{ width: '100%', overflow: 'hidden' }"
      @closed="closedPopup"
      @click-overlay="backToIndex"
    >
      <div class="popupBox">
        <div class="pop_title flex">报名信息</div>
        <div class="formBox">
          <div class="inputItem">
            <div class="label">邀请码</div>
            <div class="value normal fontStyle">{{ data.code }}</div>
          </div>
          <div class="inputItem">
            <div class="label">您的姓名</div>
            <div class="value normal fontStyle">{{ data.realName }}</div>
          </div>
          <div class="inputItem" style="margin-bottom: 6px">
            <div class="label">身份证号</div>
            <div class="value normal fontStyle">{{ data.realNo }}</div>
          </div>
          <div class="uploadPicBox">
            <div class="uploadPic flex">
              <img :src="data.noImage" alt="" />
            </div>
          </div>
          <div class="activityItem">
            <div class="label">活动场次</div>
            <div class="selectBox">
              <div class="session fontStyle flex">{{ data.session }}</div>
              <div class="time fontStyle flex">{{ data.admissionTime }}</div>
            </div>
          </div>
          <div class="inputItem" style="margin: 20px 0">
            <div class="label">手机号码</div>
            <div class="value normal fontStyle">{{ data.phone }}</div>
          </div>
        </div>
        <div class="submitbtn flex" v-if="!continueMode">
          <div class="nopass flex font" @click="continuePre(false)">
            信息不符拒绝
          </div>
          <div class="pass flex font" @click="continuePre(true)">
            信息符合通过
          </div>
        </div>
        <div
          @click="continueScan"
          class="continue-qr font flex"
          :style="{ background: !status ? '#ED4F37' : '#3D85B6' }"
          v-else
        >
          继续扫码
        </div>
      </div>
    </van-popup>
  </IndexBox>
</template>

<script>
import IndexBox from "./IndexBox.vue";
import BarScan from "../components/Camera.vue";
import { Toast } from "vant";
import { getScanDetail, authReservation } from "../api/index";
export default {
  name: "OfficialPage",
  components: {
    IndexBox,
    BarScan,
  },
  data() {
    return {
      show: false,
      continueMode: false,
      status: false,
      result: "",
      data: {},
      CameraClose:true
    };
  },
  methods: {
    startCamera() {
      this.CameraClose = false
      this.$refs.qrcode.getCameras();
    },
    endCamera(){  
      this.$refs.qrcode.stop();
      this.CameraClose = true
    },
    getResult(result) {
      let that = this;
      this.result = result;
      if (this.result) {
        this.show = true;
        getScanDetail(this.result).then((res) => {
          console.log(res);
          that.data = res.data;
        });
      }
    },
    geterror(e) {
      Toast(e);
    },
    showPopup() {
      this.show = true;
    },
    continuePre(bool) {
      let that = this;
      authReservation({
        authStatus: bool,
        code: that.data.code,
      }).then((res) => {
        console.log(res);
      });
      this.continueMode = true;
      this.status = bool;
    },
    closedPopup() {
      this.continueMode = false;
    },
    continueScan(){
      this.show = false
      this.data = []
      this.startCamera()
    },
    backToIndex(){
      this.CameraClose = true
    }
  },
};
</script>

<style lang="scss" scoped>
.fontStyle {
  color: #333333;
  font-family: "regular";
  font-size: 14px;
}
.box {
  width: 100%;
  height: 167px;
  border-radius: 6px;
  border: 2px solid #3d85b6;
  background: #ffffff;
  box-shadow: 0px 2px 3px #00000040;
  margin-bottom: 22px;
  padding-top: 19px;
  .item {
    width: 100%;
    .title {
      width: 132px;
      height: 36px;
      display: flex;
      justify-content: center;
      align-items: center;
      background-image: linear-gradient(
        90deg,
        rgba(52, 128, 179, 1) 0%,
        rgba(255, 255, 255, 1) 100%
      );
      color: #333333;
      font-family: "bold";
      //   font-weight: bold;
      font-size: 18px;
    }
    .content {
      width: 100%;
      padding: 10px 18px 14px;
      color: #333333;
      font-family: "semi";
      font-size: 14px;
      line-height: 23px;
      text-align: left;
    }
  }
}
.btn {
  width: 148px;
  height: 40px;
  border-radius: 80px;
  background: #3d85b6;
  color: #ffffff;
  font-family: "bold";
  font-size: 16px;
  margin-bottom: 19px;
}
.popupBox {
  width: 100%;
  border-radius: 6px 6px 0px 0px;
  .pop_title {
    width: 100%;
    height: 50px;
    background: rgba(61, 133, 182, 1);
    color: rgba(255, 255, 255, 1);
    font-family: "bold";
    border-radius: 6px 6px 0px 0px;
    font-size: 18px;
  }
  .submitbtn {
    width: 100%;
    height: 48px;
    display: flex;
    .font {
      color: #ffffff;
      font-family: "bold";
      font-size: 16px;
    }
    .nopass {
      width: 153px;
      height: 100%;
      background: #ed4f37;
    }
    .pass {
      width: 222px;
      height: 100%;
      background: #3d85b6;
    }
  }
  .continue-qr {
    width: 100%;
    height: 48px;
    color: #ffffff;
    font-family: "bold";
    font-size: 16px;
  }
  .formBox {
    width: 100%;
    background: #fff;
    padding: 20px 20px;
    .inputItem {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 20px;
      .label {
        font-size: 14px;
        font-family: "regular";
      }
      .value {
        display: flex;
        align-items: center;
        border-radius: 6px;
        border: 1px solid rgba(224, 224, 224, 1);
        font-family: "regular";
        font-size: 14px;
      }
      .normal {
        width: 263px;
        height: 38px;
        padding: 9px 0 8px 10px;
        box-sizing: border-box;
      }
    }
    .uploadPicBox {
      width: 100%;
      display: flex;
      justify-content: flex-end;
      margin-bottom: 20px;
      .uploadPic {
        width: 263px;
        height: 132px;
        border-radius: 6px;
        border: 1px solid rgba(224, 224, 224, 1);
        img {
          width: 96%;
          height: 96%;
        }
      }
    }
    .activityItem {
      width: 100%;
      height: 38px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .label {
        font-size: 14px;
        font-family: "regular";
      }
      .selectBox {
        width: 263px;
        height: 38px;
        display: flex;
        justify-content: space-between;
        align-items: center;

        .session {
          width: 80px;
          height: 100%;
          border-radius: 6px;
          border: 1px solid rgba(224, 224, 224, 1);
          display: flex;
          align-items: center;
          input::placeholder {
            color: #999999;
            font-family: "regular";
            font-size: 14px;
          }
        }
        .time {
          width: 173px;
          height: 38px;
          border-radius: 6px;
          border: 1px solid rgba(224, 224, 224, 1);
          display: flex;
          align-items: center;
        }
      }
    }
  }
}
</style>