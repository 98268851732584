<template>
  <div class="qrcode">
    <div id="reader"></div>
  </div>
</template>
<script>
import { Html5Qrcode } from "html5-qrcode";
import { Toast } from 'vant';
export default {
//   beforeDestroy() {
//     this.stop();
//   },
  methods: {
    getCameras() {
      Html5Qrcode.getCameras()
        .then((devices) => {
          if (devices && devices.length) {
            this.html5QrCode = new Html5Qrcode("reader");
            this.start();
          }
        })
        .catch((err) => {
          // handle err
          this.html5QrCode = new Html5Qrcode("reader");
          this.error = "ERROR: 您需要授予相机访问权限";
          this.$emit("err", this.error);
        });
    },
    start() {
      //environment后置 user前置
      this.html5QrCode
        .start(
          { facingMode: "environment" },
          {
            fps: 2,
            qrbox: { width: 250, height: 250 },
          },
          (decodedText, decodedResult) => {
            this.$emit("ok", decodedText);
            Toast.success('扫码成功')
            this.stop()
          }
        )
        .catch((err) => {
          this.$emit("err", err);

        });
    },
    stop() {
      this.html5QrCode
        .stop()
        .then((ignore) => {
          // QR Code scanning is stopped.
          console.log("QR Code scanning stopped.");
        })
        .catch((err) => {
          // Stop failed, handle it.
          console.log("Unable to stop scanning.");
        });
    },
  },
};
</script>
<style lang="scss" scoped>
.qrcode {
  position: relative;
  height: 100%;
  width: 100%;
  background: rgba($color: #000000, $alpha: 0.48);
}
#reader {
  top: 50%;
  left: 0;
  transform: translateY(-50%);
}
</style>