<template>
  <IndexBox>
    <div class="box">
      <div class="item">
        <div class="title">活动须知</div>
        <div class="content">
          国家统一组织开展的全民国防教育活动。如全国范围的国防教育宣传活动、国防知识竞赛、国防教育电视演讲大赛等。
          各种传播媒体，对全民集中进行各种宣传教育活动。宣传内容主要有国防领域的英雄模范、人民群众关心和支持国防建设的先进事迹
        </div>
      </div>
      <div class="item">
        <div class="title">活动介绍</div>
        <div class="content">
          国家统一组织开展的全民国防教育活动。如全国范围的国防教育宣传活动、国防知识竞赛、国防教育电视演讲大赛等。
          各种传播媒体，对全民集中进行各种宣传教育活动。宣传内容主要有国防领域的英雄模范、人民群众关心和支持国防建设的先进事迹
        </div>
      </div>
      <div class="item">
        <div class="title">交通出行</div>
        <div class="content">
          国家统一组织开展的全民国防教育活动。如全国范围的国防教育宣传活动、国防知识竞赛、国防教育电视演讲大赛等。
          各种传播媒体，对全民集中进行各种宣传教育活动。宣传内容主要有国防领域的英雄模范、人民群众关心和支持国防建设的先进事迹
        </div>
      </div>
    </div>
    <div class="btn-area">
      <div class="btn flex" @click="showPopup(1)">点击报名</div>
      <div class="btn flex" @click="showPopup(0)">查看二维码</div>
    </div>
    <van-popup
      v-model="show"
      position="bottom"
      round
      :style="{ width: '100%', overflow: 'hidden' }"
    >
      <div class="popupBox">
        <div class="pop_title flex">报名{{ bindStatus ? "成功" : "信息" }}</div>
        <div class="formBox" v-if="!bindStatus && !checkqrcode">
          <div class="inputItem flex-center">
            <div class="label">邀请码</div>
            <div class="value normal">
              <input
                type="text"
                class="inputNormalStyle"
                placeholder="请输入门票中邀请码（必填）"
                v-model="code"
              />
            </div>
          </div>
          <div class="inputItem flex-center">
            <div class="label">您的姓名</div>
            <div class="value normal">
              <input
                type="text"
                class="inputNormalStyle"
                placeholder="请填写您的真实姓名（必填）"
                v-model="realName"
              />
            </div>
          </div>
          <div class="inputItem" style="margin-bottom: 6px">
            <div class="label">身份证号</div>
            <div class="value normal">
              <input
                type="text"
                class="inputNormalStyle"
                placeholder="请填写您的身份证号（必填）"
                style="margin-bottom: 6px"
                v-model="realNo"
              />
            </div>
          </div>
          <div class="uploadPicBox flex">
            <div class="uploadPic flex">
              <div class="default flex">
                <van-uploader
                  :after-read="uploadImgs"
                  upload-text="请上传您的身份证人像面或户口本照片"
                  v-model="fileList"
                  :show-upload="showUpload"
                  @delete="deleteImg"
                />
              </div>
            </div>
          </div>
          <div class="activityItem">
            <div class="label">活动场次</div>
            <div class="selectBox">
              <div class="session" @click="showSelect(0)">
                <input
                  type="text"
                  class="sessionStyle"
                  v-model="sessionVal"
                  readonly
                />
                <div class="imgg flex">
                  <img
                    src="../assets/images/activity-arrow.png"
                    alt=""
                    :class="leftArea ? 'img rotate' : 'img'"
                  />
                </div>
              </div>
              <div class="time" @click="showSelect(1)">
                <input
                  type="text"
                  class="timeStyle"
                  v-model="timeVal"
                  readonly
                />
                <div class="imgg flex">
                  <img
                    src="../assets/images/activity-arrow.png"
                    alt=""
                    :class="rightArea ? 'img rotate' : 'img'"
                  />
                </div>
              </div>
            </div>
          </div>
          <div class="activity-select-area" v-if="leftArea || rightArea">
            <div class="area-position">
              <div class="session-select" v-show="leftArea">
                <div
                  class="select-item mb16"
                  @click="selectSession(periodArr[0], 0)"
                >
                  {{ periodArr[0] }}
                </div>
                <div
                  class="select-item"
                  @click="selectSession(periodArr[1], 1)"
                >
                  {{ periodArr[1] }}
                </div>
              </div>
              <!-- 占位盒子 -->
              <div class="session-select-null" v-show="!leftArea"></div>
              <div class="time-select" v-show="rightArea">
                <div
                  :class="index < 2 ? 'select-item mb16' : 'select-item'"
                  v-for="(item, index) in timeStrArr[timeIndex]"
                  :key="index"
                  @click="selectTime(item)"
                >
                  {{ item }}
                </div>
              </div>
            </div>
          </div>
          <div class="inputItem flex-center" style="margin: 20px 0">
            <div class="label">手机号码</div>
            <div class="value normal">
              <input
                type="text"
                class="inputNormalStyle"
                placeholder="请填写您的电话号码（必填）"
                v-model="phone"
              />
            </div>
          </div>
          <div class="submitbtn flex">
            <div class="submit flex" @click="submit">提交报名</div>
          </div>
        </div>
        <div class="qrcode" v-if="bindStatus && !checkqrcode">
          <div class="container">
            <div class="notice">二维码作为入场凭证，请妥善保管</div>
            <div class="qrcodeImg">
              <img :src="qrcodebase64" alt="" />
            </div>
            <div class="saveQrcode flex">请长按图片保存二维码</div>
          </div>
        </div>
        <div class="qrcode" v-if="checkqrcode">
          <div class="container" style="top: 17px">
            <div class="search-box">
              <div class="value normal">
                <input
                  type="text"
                  class="inputNormalStyle"
                  placeholder="请输入门票中邀请码查询"
                  v-model="checkqrcodeVal"
                />
              </div>
              <div class="search-btn flex" @click="checkByCode">查询</div>
            </div>
            <div class="notice">二维码作为入场凭证，请妥善保管</div>
            <div class="qrcodeImg">
              <div class="demo" v-if="!imgBycheck"></div>
              <img :src="imgBycheck" alt="" v-else />
            </div>
            <div class="name" v-if="userName">{{ userName }}</div>
            <div class="saveQrcode flex">请长按图片保存二维码</div>
          </div>
        </div>
      </div>
    </van-popup>
  </IndexBox>
</template>

<script>
import { Toast, Notify } from "vant";
import IndexBox from "./IndexBox.vue";
import {
  uploadPic,
  bindReservation,
  getScanDetail,
  getQrcode,
} from "../api/index.js";
export default {
  name: "ClientPage",
  components: {
    IndexBox,
  },
  data() {
    return {
      show: false,
      selectMode: false, //选择活动场次
      leftArea: false,
      rightArea: false,
      periodArr: ["上午场", "下午场"],
      timeStrArr: [
        ["09:00-10:00", "10:00-11:00", "11:00-12:00"],
        ["13:00-14:00", "14:00-15:00", "15:00-16:00"],
      ],
      timeIndex: 0,
      fileList: [],
      showUpload: true,

      code: "",
      noImage: "",
      phone: "",
      realName: "",
      realNo: "",
      sessionVal: "请输入",
      timeVal: "请选择入场时间段",
      bindStatus: false,
      checkqrcode: false,
      qrcodebase64: "",
      checkqrcodeVal: "",
      imgBycheck: "",
      userName: "",
    };
  },
  methods: {
    showPopup(bool) {
      if (bool) {
        this.checkqrcode = false;
        this.show = true;
      } else {
        this.imgBycheck = ""
        this.checkqrcodeVal = ""
        this.userName = ""
        this.checkqrcode = true;
        this.show = true;
      }
    },
    checkByCode() {
      let that = this;
      getQrcode(this.checkqrcodeVal).then((res) => {
        console.log(res);
        that.imgBycheck = res.data;
      });
      getScanDetail(this.checkqrcodeVal).then((res) => {
        if(res.code == 200){
          that.userName = res.data.realName;
        }else{
          Toast(res.msg)
        }
      });
    },
    //显示活动场次选择区域
    showSelect(num) {
      if (num === 0) {
        this.leftArea = !this.leftArea;
      } else {
        if (this.sessionVal !== "请输入") {
          this.rightArea = !this.rightArea;
        } else {
          Toast("请选择活动场次！");
        }
      }
    },
    selectSession(str, index) {
      this.sessionVal = str;
      this.timeIndex = index;
      this.leftArea = !this.leftArea;
    },
    selectTime(str) {
      this.timeVal = str;
      this.rightArea = !this.rightArea;
    },
    deleteImg() {
      this.showUpload = true;
    },
    submit() {
      let that = this;

      let postData = {
        admissionTime: this.timeVal,
        session: this.sessionVal,
        code: this.code,
        noImage: this.noImage,
        realName: this.realName,
        phone: this.phone,
        realNo: this.realNo,
      };
      console.log(postData);
      let canPass = true;
      for (const key in postData) {
        if (postData[key] == "") {
          canPass = false;
        }
        if (key == "admissionTime" && postData[key] == "请选择入场时间段") {
          canPass = false;
        }
        if (key == "session" && postData[key] == "请输入！") {
          canPass = false;
        }
      }
      if (canPass) {
        bindReservation(postData).then((res) => {
          console.log(res);
          if (res.message == "绑定成功") {
            that.qrcodebase64 = res.data;
            that.bindStatus = true;
          } else {
            Notify({ type: "danger", message: res.msg });
          }
        });
      } else {
        Toast("请完善报名信息！");
        return;
      }
    },
    showQrCode() {},
    async compressImg(base64, scale, name) {
      console.log(`执行缩放程序，scale=${scale}`);
      // 处理缩放，转换格式
      // 用canvas来压缩
      const img = new Image();
      img.src = base64;
      return new Promise(function (resolve) {
        img.onload = async function () {
          const canvas = document.createElement("canvas");
          const ctx = canvas.getContext("2d");
          canvas.setAttribute("width", this.width);
          canvas.setAttribute("height", this.height);
          ctx.clearRect(0, 0, canvas.width, canvas.height);
          ctx.drawImage(img, 0, 0, canvas.width, canvas.height);
          // 转成base64 文件
          base64 = canvas.toDataURL("image/jpeg", 0.5);
          const arr = base64.split(",");
          const mime = arr[0].match(/:(.*?);/)[1];
          const bytes = atob(arr[1]);
          const bytesLength = bytes.length;
          const u8arr = new Uint8Array(bytesLength);
          for (let i = 0; i < bytes.length; i++) {
            u8arr[i] = bytes.charCodeAt(i);
          }
          const file = await new File([u8arr], name, { type: mime });
          resolve(file);
        };
      });
    },
    async uploadImgs(file) {
      let that = this;
      this.fileList = [];
      // 大于2.5MB的jpeg和png图片都缩小像素上传
      if (/\/(?:jpeg|png)/i.test(file.file.type) && file.file.size > 614400) {
        let file1 = await this.compressImg(file.content, 0.5, file.file.name);
        that.base64Parse(file1);
      } else {
        this.base64Parse(file.file);
      }
    },
    base64Parse(data) {
      let that = this;
      const formPost = new FormData();
      formPost.append("file", data);
      console.log(formPost);

      uploadPic(formPost).then((res) => {
        console.log(res);

        that.showUpload = false;

        that.fileList = [
          {
            url: res.data,
          },
        ];
        that.noImage = res.data;
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.box {
  width: 100%;
  height: 618px;
  border-radius: 6px;
  border: 2px solid #3d85b6;
  background: #ffffff;
  box-shadow: 0px 2px 3px #00000040;
  margin-bottom: 22px;
  padding-top: 19px;
  .item {
    width: 100%;
    .title {
      width: 132px;
      height: 36px;
      display: flex;
      justify-content: center;
      align-items: center;
      background-image: linear-gradient(
        90deg,
        rgba(52, 128, 179, 1) 0%,
        rgba(255, 255, 255, 1) 100%
      );
      color: #333333;
      font-family: "bold";
      //   font-weight: bold;
      font-size: 18px;
    }
    .content {
      width: 100%;
      padding: 10px 18px 14px;
      color: #333333;
      font-family: "semi";
      font-size: 14px;
      line-height: 23px;
      text-align: left;
    }
  }
}
.btn-area {
  width: 100%;
  height: 40px;
  margin-bottom: 35px;
  display: flex;
  justify-content: space-between;
}
.btn {
  width: 148px;
  height: 100%;
  border-radius: 80px;
  background: #3d85b6;
  color: #ffffff;
  font-family: "bold";
  font-size: 16px;
}
.popupBox {
  width: 100%;
  border-radius: 6px 6px 0px 0px;
  .pop_title {
    width: 100%;
    height: 50px;
    background: rgba(61, 133, 182, 1);
    color: rgba(255, 255, 255, 1);
    font-family: "bold";
    border-radius: 6px 6px 0px 0px;
    font-size: 18px;
  }
  .formBox {
    width: 100%;
    background: #fff;
    padding: 20px 20px;
    .inputItem {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 20px;
      .label {
        font-size: 14px;
        font-family: "regular";
      }
      .value {
        border-radius: 6px;
        border: 1px solid rgba(224, 224, 224, 1);
        color: rgba(153, 153, 153, 1);
        font-family: "regular";
        font-size: 14px;
      }
      .normal {
        width: 263px;
        height: 38px;
        padding: 9px 0 8px 10px;
        box-sizing: border-box;
      }
      .inputNormalStyle {
        width: 100%;
        height: 100%;
        box-sizing: border-box;
        border: none;
        font-size: 14px;
        color: #333;
      }
      input::placeholder {
        font-size: 14px;
        font-family: "regular";
      }
    }
    .uploadPicBox {
      width: 100%;
      display: flex;
      justify-content: flex-end;
      margin-bottom: 20px;
      .uploadPic {
        width: 263px;
        height: 132px;
        border-radius: 6px;
        border: 1px solid rgba(224, 224, 224, 1);
        .default {
          width: 100%;
          height: 132px;
          // display: flex;
          // flex-direction: column;
          // align-items: center;
          // margin-top: 30px;

          .default-txt {
            color: #999999;
            font-family: "regular";
            font-size: 12px;
          }
        }
      }
    }
    .activityItem {
      width: 100%;
      height: 38px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .label {
        font-size: 14px;
        font-family: "regular";
      }
      .selectBox {
        width: 263px;
        height: 38px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        .rotate {
          // transform: rotate(180deg);
          // transition: transform 0.5s ease-in-out;
          animation: rotate-open 0.5s linear forwards;
        }
        @keyframes rotate-open {
          0% {
            transform: rotate(0deg);
          }
          100% {
            transform: rotate(-180deg);
          }
        }
        .rotate-start {
          // transform: rotate(180deg);
          // transition: transform 0.5s ease-in-out;
          animation: rotate-close 0.5s linear forwards;
        }
        @keyframes rotate-close {
          0% {
            transform: rotate(-180deg);
          }
          100% {
            transform: rotate(0deg);
          }
        }

        .imgg {
          width: 16px;
          height: 16px;
          .img {
            width: 16px;
            height: 16px;
          }
        }

        .session {
          width: 80px;
          height: 100%;
          border-radius: 6px;
          border: 1px solid rgba(224, 224, 224, 1);
          padding: 8px 11px 8px 11px;
          display: flex;
          align-items: center;
          input::placeholder {
            color: #999999;
            font-family: "regular";
            font-size: 14px;
          }
          .sessionStyle {
            width: 42px;
            height: 21px;
            font-size: 14px;
            font-family: "regular";
            color: #333;
            box-sizing: border-box;
            border: none;
          }
        }
        .time {
          width: 173px;
          height: 38px;
          border-radius: 6px;
          border: 1px solid rgba(224, 224, 224, 1);
          padding: 8px 16px 8px 29px;
          display: flex;
          align-items: center;
          .timeStyle {
            width: 112px;
            height: 21px;
            font-size: 14px;
            font-family: "regular";
            color: #333;
            box-sizing: border-box;
            border: none;
          }
        }
      }
    }
    .activity-select-area {
      width: 100%;
      display: flex;
      justify-content: flex-end;
      // height: 115px;
      margin-top: 6px;
      .area-position {
        width: 263px;
        height: 100%;
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        .select-item {
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: 14px;
          font-family: "regular";
          color: #333;
        }
        .mb16 {
          margin-bottom: 16px;
        }
        .session-select {
          width: 80px;
          height: 78px;
          border-radius: 6px;
          border: 1px solid rgba(224, 224, 224, 1);
          padding: 10px 0;
        }
        .session-select-null {
          width: 80px;
          height: 78px;
        }
        .time-select {
          width: 173px;
          height: 115px;
          border-radius: 6px;
          border: 1px solid rgba(224, 224, 224, 1);
          padding: 10px 0;
          .type {
            width: 100%;
            height: 100%;
            background: red;
          }
        }
      }
    }
    .submitbtn {
      width: 100%;
      height: 40px;
      .submit {
        width: 148px;
        height: 40px;
        border-radius: 80px;
        background: #3d85b6;
        font-size: 16px;
        color: #fff;
        font-family: "bold";
      }
    }
  }
  .qrcode {
    width: 100%;
    height: 501px;
    background: #fff;
    position: relative;
    .search-box {
      width: 335px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 40px;
      .search-btn {
        width: 60px;
        height: 39px;
        border-radius: 6px;
        background: #3d85b6;
        font-family: "bold";
        font-size: 14px;
        color: #fff;
      }
      .inputNormalStyle {
        width: 100%;
        height: 100%;
        box-sizing: border-box;
        border: none;
        font-size: 14px;
        color: #333;
      }
      input::placeholder {
        font-size: 14px;
        font-family: "regular";
      }
      .value {
        border-radius: 6px;
        border: 1px solid rgba(224, 224, 224, 1);
        color: rgba(153, 153, 153, 1);
        font-family: "regular";
        font-size: 14px;
      }
      .normal {
        width: 263px;
        height: 38px;
        padding: 9px 0 8px 10px;
        box-sizing: border-box;
      }
    }

    .container {
      position: absolute;
      top: 40px;
      left: 64px;
      width: 246px;
      display: flex;
      flex-direction: column;
      align-items: center;
      .notice {
        color: #ed4f37;
        font-family: "bold";
        font-size: 14px;
        margin-bottom: 14px;
      }
      .qrcodeImg {
        width: 246px;
        height: 246px;
        border: 2px solid #3d85b6;
        padding: 8px;
        margin-bottom: 6px;
        
        .demo {
          width: 100%;
          height: 100%;
          background: #ccc;
        }
        img {
          width: 100%;
          height: 100%;
        }
      }
      .name {
          height: 21px;
          color: #3d85b6;
          font-family: 'bold';
          font-size: 14px;
          margin-bottom: 16px;
        }
      .saveQrcode {
        width: 246px;
        height: 40px;
        // border-radius: 80px;
        background: #3d85b6;
        color: #ffffff;
        font-family: "bold";
        font-size: 16px;
      }
    }
  }
}
</style>