import request from '../utils/request';
import request2 from '../utils/request_2'

export function uploadPic(data){
    return request2({
        url:'/webApi/national/upload',
        method:'POST',
        data:data
    })
}

export function bindReservation(data){
    return request({
        url:'/webApi/national/bindReservation',
        method:'POST',
        data:data
    })
}


export function getScanDetail(code){
    return request({
        url:`/webApi/national/scanCode/${code}`,
        method:'GET'
    })
}

export function authReservation(data){
    return request({
        url:'webApi/national/authReservation',
        method:'POST',
        data:data
    })
}

export function getQrcode(code){
    return request({
        url:`webApi/national/getCode/${code}`,
        method:'GET'
    })
}



